import { Head } from '@inertiajs/react';

import BlogFocus from '@/pages/site/home/components/BlogFocus.jsx';
import Newsletter from '@/pages/site/home/components/Newsletter.jsx';
import { createSiteLayout } from '@/pages/site/layouts/SiteLayout.jsx';

import Hero from './components/Hero.jsx';
import HomeFaq from './components/HomeFaq.jsx';
import HowTo from './components/HowTo.jsx';
import Trust from './components/Trust.jsx';
import Unlock from './components/Unlock.jsx';
import WhyGoldframer from './components/WhyGoldframer.jsx';
import WhyInvest from './components/WhyInvest.jsx';

export default function SiteHome() {
  return (
    <>
      <Head>
        <title>Your Gateway to Islamic Art Investments</title>
        <meta
          head-key="description"
          name="description"
          content="Goldframer is revolutionizing art investment by making it easy to own a piece of exquisite Islamic art. Explore our curated selection of artworks and start investing in the rich heritage of Islamic culture today."
        />
      </Head>
      <Hero />
      <WhyInvest />
      <WhyGoldframer />
      <Trust />
      <BlogFocus />
      <Unlock />
      <HowTo />
      <HomeFaq />
      <Newsletter />
    </>
  );
}

SiteHome.layout = page => createSiteLayout(page);
